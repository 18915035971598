
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div align="center">
  <img src="https://github.com/hitechline/react/raw/main/.github/logo.png" height="100" width="100" />
    </div>
    <h1 {...{
      "id": "reactools"
    }}><a parentName="h1" {...{
        "href": "#reactools"
      }}><span parentName="a" {...{
          "className": "heading-link"
        }}>{`#`}</span></a>{`Reactools`}</h1>
    <p>{`Utilities to facilitate the build user interfaces with React.`}</p>
    <br />
    <h4 {...{
      "id": "--our-proposal"
    }}><a parentName="h4" {...{
        "href": "#--our-proposal"
      }}><span parentName="a" {...{
          "className": "heading-link"
        }}>{`#`}</span></a>{`- Our Proposal`}</h4>
    <p>{`Is to have a set of tools that are easy to use and implement that help the
developer create applications with hight scalability.`}</p>
    <br />
    <h4 {...{
      "id": "--our-foucus"
    }}><a parentName="h4" {...{
        "href": "#--our-foucus"
      }}><span parentName="a" {...{
          "className": "heading-link"
        }}>{`#`}</span></a>{`- Our Foucus`}</h4>
    <p>{`Is not just have performace hooks but a whole portfolio of resources to
improve your project.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;